
import { defineComponent, onMounted, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { Picklist } from '../../models/picklist.model';
import { document, close, play } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { JnService } from '../../services/jn.service';
import { EsService } from '../../services/es.service';
import { Utils } from '../../services/utils';
import moment from 'moment';

import {
  SearchTypes,
  QueryTypes,
  OrderingTypes,
  Categories,
} from '../../models/enums.model';

addIcons({
  document: document,
  close: close,
  play: play,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItemDivider,
  IonIcon,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton,
  modalController,
  IonDatetime,
  IonFab,
  IonFabButton,
} from '@ionic/vue';
import { SearchData } from '@/models/search.model';

export default defineComponent({
  name: 'JnAreaTematicaSearchParams',
  props: {
    searchData: {
      type: SearchData,
      required: true,
    },
  },
  components: {
    IonContent,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    // IonItemDivider,
    IonIcon,
    IonInput,
    IonToolbar,
    IonHeader,
    IonPage,
    IonTitle,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonFab,
    IonFabButton,
  },

  setup(props) {

    const optMaterie = ref([] as Array<string>);
    const optArgomenti = ref([] as Array<string>);
    const optTags = ref([] as Array<string>);
    const optTipoDocumento = ref([] as Array<string>);
    const optText = ref('');
    const optType = ref(QueryTypes.OR);
    const optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;

    const tipiQuery = ref([] as Array<Picklist>);
    const tipiOrdinamento = ref([] as Array<Picklist>);

    const materie = ref([] as Array<Picklist>);
    const categorie = ref([] as Array<Picklist>);
    const argomenti = ref([] as Array<Picklist>);
    const areeTematiche = ref([] as Array<Picklist>);
    const tags = ref([] as Array<Picklist>);
    const tipiDocumento = ref([] as Array<Picklist>);

    const searchData = ref(props.searchData);

    let areeTematicheRaw!: any;
    let tagsRaw!: any;

    onMounted(async () => {
      tipiQuery.value = JnService.getQueryTypes();
      tipiOrdinamento.value = JnService.getTipiOrdinamento();
      tipiDocumento.value = JnService.getTipiDocumento();

      areeTematicheRaw = JSON.parse(
        await EsService.getOptions('struttura-aree-tematiche')
      );

      for (const areaTematica of areeTematicheRaw['aree-tematiche']) {
        areeTematiche.value.push(
          new Picklist({ label: areaTematica.name, value: areaTematica.id })
        );
        if (areaTematica.id == searchData.value.idAreaTematica) {
          for (const materia of areaTematica.materie) {
            materie.value.push(
              new Picklist({ label: materia.name, value: materia.id })
            );
          }
        }
        materie.value.sort(Utils.sort('label', 'asc'));
      }
      tagsRaw = JSON.parse(await EsService.getOptions('tags'));
      for (const tag of tagsRaw) {
        if (tag.value != 'ROOT') {
          tags.value.push(new Picklist({ value: tag.id, label: tag.value }));
        }
      }
    });

    const search = async () => {
      searchData.value.keywords = optText.value;
      searchData.value.queryType = optType.value;
      if (optTipoOrdinamento) {
        searchData.value.orderingType = optTipoOrdinamento;
      }

      const idMaterie: string[] = [];
      for (const m of optMaterie.value) {
        idMaterie.push(m);
      }
      searchData.value.idMateria = idMaterie;

      const idArgomenti: string[] = [];
      for (const a of optArgomenti.value) {
        idArgomenti.push(a);
      }
      searchData.value.idArgomento = idArgomenti;

      const tags: string[] = [];
      for (const t of optTags.value) {
        tags.push(t);
      }
      searchData.value.tags = tags;

      const tipiDocumento: string[] = [];
      for (const td of optTipoDocumento.value) {
        tipiDocumento.push(td);
      }
      searchData.value.idCategory = tipiDocumento;

      modalController.dismiss(searchData);
    };

    const close = async () => {
      modalController.dismiss(undefined);
    };
/*
    const clear = async () => {
      optText.value = '';
      optType.value = QueryTypes.OR;
      optMaterie.value = [];
      optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;
      optArgomenti.value = [];
      optTags.value = [];
      optTipoDocumento.value = [];
    };
*/
    const changeMateria = async () => {
      argomenti.value = [];
      optArgomenti.value = [];
      for (const selectedMateria of optMaterie.value) {
        for (const areaTematica of areeTematicheRaw['aree-tematiche']) {
          for (const materia of areaTematica.materie) {
            if (materia.id == selectedMateria) {
              for (const argomento of materia.argomenti) {
                argomenti.value.push(
                  new Picklist({ label: argomento.name, value: argomento.id })
                );
              }
            }
          }
        }
      }
    };

    return {
      tipiQuery,
      optArgomenti,
      optMaterie,
      optText,
      optType,
      optTipoOrdinamento,
      tipiOrdinamento,
      categorie,
      changeMateria,
      materie,
      argomenti,
      search,
      close,
    };
  },
});
